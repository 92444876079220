// src/components/StoryDisplay.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Fade,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email'; // New Icon
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom'; // Import useParams
import { fetch_story_by_uuid } from '../storyService'; // Import the new fetch function
import ReactGA from 'react-ga4'; // Ensure ReactGA is still imported for custom events

function StoryDisplay() {
  const { uuid } = useParams(); // Get UUID from URL parameters
  const [story, setStory] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [base64Image, setBase64Image] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [downloadError, setDownloadError] = useState(null);
  const [loadingStory, setLoadingStory] = useState(true);
  const [storyError, setStoryError] = useState(null);

  // States for Email Modal
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailSending, setEmailSending] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(null);
  const [emailError, setEmailError] = useState(null);

  // New state for controlling Fade
  const [fadeIn, setFadeIn] = useState(false);

  const WEBSITE_DOMAIN = process.env.REACT_APP_WEBSITE_DOMAIN;

  useEffect(() => {
    // Reset fade state when uuid changes
    setFadeIn(false);

    const fetchStory = async () => {
      try {
        const { story, image_url } = await fetch_story_by_uuid(uuid);
        setStory(story);
        setImageUrl(image_url);
        setLoadingStory(false);

        // **Log 'created_story_displayed' event after successfully fetching the story**
        ReactGA.event({
          category: 'StoryDisplay',
          action: 'created_story_displayed',
          label: 'Story Display Page Loaded',
        });
      } catch (error) {
        console.error('Error fetching story:', error);
        setStoryError(error.message);
        setLoadingStory(false);
      }
    };

    fetchStory();
  }, [uuid]);

  useEffect(() => {
    if (!loadingStory && !storyError) {
      // Trigger fade-in when story is loaded successfully
      setFadeIn(true);
    }
  }, [loadingStory, storyError]);

  useEffect(() => {
    const fetchBase64Image = async () => {
      if (!imageUrl) return;

      setLoadingImage(true);
      setImageError(false);

      try {
        const response = await fetch(`/api/getImage?url=${encodeURIComponent(imageUrl)}`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to fetch image.');
        }
        const data = await response.json();
        setBase64Image(data.base64);
      } catch (error) {
        console.error('Error fetching Base64 image:', error);
        setImageError(true);
      } finally {
        setLoadingImage(false);
      }
    };

    fetchBase64Image();
  }, [imageUrl]);

  const handleDownload = async () => {
    setIsGenerating(true);
    setDownloadError(null);
    try {
      const doc = new jsPDF('p', 'pt', 'a4');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const margin = 40;
      let yPosition = margin;

      // Add Header
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(20);
      doc.text('Verhaal van Verhalen-maker.nl', pageWidth / 2, yPosition, { align: 'center' });
      yPosition += 30;

      // Add Image
      if (base64Image) {
        try {
          // Calculate image dimensions to fit within the page margins
          const imgProps = doc.getImageProperties(base64Image);
          const imgWidth = pageWidth - 2 * margin;
          const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

          // Add the image to the PDF
          doc.addImage(base64Image, 'PNG', margin, yPosition, imgWidth, imgHeight);
          yPosition += imgHeight + 20; // Add space after the image
        } catch (error) {
          console.error('Error adding image to PDF:', error);
        }
      }

      // Add Story Title (Optional)
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(16);
      doc.text('Jouw Verhaal', pageWidth / 2, yPosition, { align: 'center' });
      yPosition += 25;

      // Add Story Text
      doc.setFont('Helvetica', 'normal');
      doc.setFontSize(12);
      const textLines = doc.splitTextToSize(story, pageWidth - 2 * margin);

      // Function to add text with pagination
      const addText = (lines) => {
        let currentY = yPosition;
        lines.forEach((line) => {
          if (currentY > pageHeight - margin) {
            doc.addPage();
            currentY = margin;
          }
          doc.text(line, margin, currentY);
          currentY += 14; // Adjust line height as needed
        });
      };

      addText(textLines);

      // Add Page Numbers
      const totalPages = doc.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(`Pagina ${i} van ${totalPages}`, pageWidth / 2, pageHeight - 10, { align: 'center' });
      }

      // Save the PDF
      doc.save('verhaal.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
      setDownloadError('Er is een fout opgetreden bij het genereren van de PDF. Probeer het opnieuw.');
    } finally {
      setIsGenerating(false);
    }
  };

  // Handle sharing via WhatsApp
  const handleShare = () => {
    // Access the WEBSITE_DOMAIN environment variable

    // Construct the share URL dynamically
    const shareUrl = `https://${WEBSITE_DOMAIN}/story/${uuid}`;
    const text = encodeURIComponent(`Bekijk dit verhaal: ${shareUrl}`);
    const whatsappUrl = `https://wa.me/?text=${text}`;
    window.open(whatsappUrl, '_blank');

    // **Log 'share_whatsapp_clicked' event**
    ReactGA.event({
      category: 'User Interaction',
      action: 'share_whatsapp_clicked',
      label: 'User shared story via WhatsApp',
    });
  };

  // Handle Email Modal Open
  const handleOpenEmailModal = () => {
    setEmail('');
    setEmailSuccess(null);
    setEmailError(null);
    setEmailModalOpen(true);

    // **Log 'send_email_clicked' event when the send email button is clicked to open the modal**
    ReactGA.event({
      category: 'User Interaction',
      action: 'send_email_clicked',
      label: 'User clicked send email button',
    });
  };

  // Handle Email Modal Close
  const handleCloseEmailModal = () => {
    setEmailModalOpen(false);
  };

  // Handle Send Email
  const handleSendEmail = async () => {
    setEmailSending(true);
    setEmailSuccess(null);
    setEmailError(null);

    try {
      // Basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        throw new Error('Ongeldig e-mailadres.');
      }

      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, uuid }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Fout bij het verzenden van de e-mail.');
      }

      const data = await response.json();
      if (data.message) {
        setEmailSuccess(data.message);
      } else {
        setEmailSuccess('E-mail succesvol verzonden!');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setEmailError(error.message);
    } finally {
      setEmailSending(false);
    }
  };

  return (
    <Fade in={fadeIn} timeout={500}>
      <Container
        maxWidth="md"
        sx={{
          mt: 6,
          mb: 6,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingX: { xs: 2, sm: 4 },
        }}
      >
        {loadingStory ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        ) : storyError ? (
          <Typography color="error" align="center" sx={{ my: 4 }}>
            {storyError}
          </Typography>
        ) : (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              Jouw Verhaal
            </Typography>

            {loadingImage && (
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                <CircularProgress />
              </Box>
            )}

            {imageError && (
              <Typography color="error" align="center" sx={{ my: 4 }}>
                {/* Optional: Display an error message for image loading */}
                
              </Typography>
            )}

            {base64Image && (
              <Box
                sx={{
                  width: '100%',
                  aspectRatio: '1 / 1', // Ensures the box is always a square
                  backgroundImage: `url(${base64Image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: 2,
                  my: 4,
                }}
              />
            )}

            <Box sx={{ width: '100%' }}>
              <ReactMarkdown>{story}</ReactMarkdown>
            </Box>

            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                flexWrap: 'wrap',
              }}
            >
              <Button
                variant="contained"
                color="success"
                startIcon={<WhatsAppIcon />}
                onClick={handleShare}
                sx={{ textTransform: 'none', fontWeight: 600 }}
              >
                Deel via WhatsApp
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={isGenerating ? <CircularProgress size={20} /> : <DownloadIcon />}
                onClick={handleDownload}
                disabled={isGenerating}
                sx={{ textTransform: 'none', fontWeight: 600 }}
              >
                {isGenerating ? 'Genereren...' : 'Download Verhaal'}
              </Button>
              <Button
                variant="contained" // Changed from "outlined" to "contained"
                color="primary"    // Changed from "secondary" to "primary"
                startIcon={<EmailIcon />}
                onClick={handleOpenEmailModal}
                sx={{
                  textTransform: 'none',
                  fontWeight: 600,
                  // Optional: Add additional styling for better visibility
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
              >
                Verstuur verhaal
              </Button>
            </Box>

            {downloadError && (
              <Typography color="error" align="center" sx={{ mt: 2 }}>
                {downloadError}
              </Typography>
            )}
          </>
        )}

        {/* Email Modal */}
        <Dialog open={emailModalOpen} onClose={handleCloseEmailModal}>
          <DialogTitle>Verstuur verhaal naar E-mail</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="E-mailadres"
              type="email"
              fullWidth
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={emailSending}
            />
            {emailError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {emailError}
              </Alert>
            )}
            {emailSuccess && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {emailSuccess}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEmailModal} disabled={emailSending}>
              Annuleren
            </Button>
            <Button onClick={handleSendEmail} disabled={emailSending || emailSuccess}>
              {emailSending ? <CircularProgress size={20} /> : 'Verstuur'}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Fade>
  );
}

export default StoryDisplay;
