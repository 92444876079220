// src/constants/leesniveaus.js

export const leesniveaus = [
    // { value: 0, label: 'AVI Start' },
    // { value: 1, label: 'M3' },
    { value: 2, label: 'E3' },
    { value: 3, label: 'M4' },
    { value: 4, label: 'E4' },
    { value: 5, label: 'M5' },
    { value: 6, label: 'E5' },
    { value: 7, label: 'M6' },
    { value: 8, label: 'E6' },
    { value: 9, label: '7+' },
  ];
  