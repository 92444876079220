import { useState, useRef, useCallback, useEffect } from 'react';

function useProgress() {
  const [progress, setProgress] = useState(0);
  const progressIntervalRef = useRef(null);

  const startProgress = useCallback(() => {
    const startTime = Date.now();

    progressIntervalRef.current = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const elapsedMinutes = elapsed / 60000;

      if (elapsedMinutes < 1) {
        setProgress(Math.min((elapsedMinutes / 1) * 60, 60));
      } else if (elapsedMinutes < 2) {
        setProgress(Math.min(60 + ((elapsedMinutes - 1) / 1) * 30, 90));
      } else if (elapsedMinutes < 3) {
        setProgress(Math.min(90 + ((elapsedMinutes - 2) / 1) * 10, 100));
      } else {
        setProgress(100);
        clearProgress();
      }
    }, 1000);
  }, []);

  const clearProgress = useCallback(() => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
      progressIntervalRef.current = null;
    }
    setProgress(0);
  }, []);

  useEffect(() => {
    return () => {
      clearProgress();
    };
  }, [clearProgress]);

  return { progress, startProgress, clearProgress };
}

export default useProgress;
