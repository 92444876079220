// src/theme.js
import { createTheme } from '@mui/material/styles';
import { deepPurple, grey, amber } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      light: deepPurple[300],
      main: deepPurple[500],
      dark: deepPurple[700],
      contrastText: '#fff',
    },
    secondary: {
      light: grey[300],
      main: grey[500],
      dark: grey[700],
      contrastText: '#fff',
    },
    accent: { // Aangepaste kleurcategorie
      light: amber[300],
      main: amber[500],
      dark: amber[700],
      contrastText: '#000',
    },
    background: {
      default: grey[100],
    },
    text: {
      primary: '#333333',
      secondary: '#555555',
    },
  },
  typography: {
    // Gebruik het standaard Roboto font met enkele aanpassingen
    fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`,
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
      letterSpacing: '0.05em',
      textTransform: 'none',
      color: deepPurple[500],
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
      letterSpacing: '0.05em',
      color: deepPurple[500],
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
      letterSpacing: '0.05em',
      color: deepPurple[500],
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
      letterSpacing: '0.05em',
      color: deepPurple[500],
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
      letterSpacing: '0.05em',
      color: deepPurple[500],
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
      letterSpacing: '0.05em',
      color: deepPurple[500],
    },
    subtitle1: {
      fontSize: '1.2rem', // Vergroot van 1rem naar 1.2rem
      fontStyle: 'italic', // Voeg een speels tintje toe
      color: grey[700],
    },
    body1: {
      fontSize: '1.2rem', // Vergroot van 1rem naar 1.2rem
      lineHeight: 1.8, // Optioneel: verhoog de line-height voor betere leesbaarheid
      color: '#333333',
    },
    body2: {
      fontSize: '1.1rem', // Voeg indien nodig een body2 variant toe
      lineHeight: 1.7,
      color: '#555555',
    },
    button: {
      textTransform: 'none', // Geen hoofdletters voor knoppen
      fontWeight: 600,
      fontSize: '1rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: deepPurple[500],
          '&:hover': {
            backgroundColor: deepPurple[700],
          },
        },
        containedSecondary: {
          backgroundColor: grey[500],
          '&:hover': {
            backgroundColor: grey[700],
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          transition: 'color 0.3s ease',
          '&:hover': {
            color: deepPurple[300], // Gebruik light purple (deepPurple[300]) voor hover effect
          },
          '&:focus, &:active': {
            color: deepPurple[300], // Zorg ervoor dat dezelfde kleur wordt gebruikt voor focus en active states
          },
        },
      },
    },
  },
});

export default theme;
