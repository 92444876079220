// src/pages/Contact.js

import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Contact = () => {
  return (
    <Box
      sx={{
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '80vh',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Neem contact met ons op.
      </Typography>
      <Typography variant="body1">
        Heb je vragen of opmerkingen? Stuur ons een e-mail op{' '}
        <Link href="mailto:info@verhalen-maker.nl" underline="hover">
          info@verhalen-maker.nl
        </Link>
      </Typography>
    </Box>
  );
};

export default Contact;
