import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box } from '@mui/material';

const SessionPage = () => {
  const { id } = useParams(); // Get the session ID from the URL

  return (
    <Box textAlign="center" mt={10}>
      <Typography variant="h4" gutterBottom>
        Session {id}
      </Typography>
      <Typography variant="subtitle1">
        Dit is een placeholderpagina voor Session {id}. Voeg hier de inhoud voor deze sessie toe.
      </Typography>
    </Box>
  );
};

export default SessionPage;
