// src/components/StoryForm.js

import React from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Box,
} from '@mui/material';

function StoryForm({
  activeStep,
  steps,
  completed,
  getStepContent,
  handleNext,
  handleBack,
  isLoading,
  formValues,
  setActiveStep,
  handleChange,
}) {
  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel onClick={() => setActiveStep(index)}>{label}</StepLabel>
            {activeStep === index && (
              <StepContent>
                {getStepContent(index)}
                <Box sx={{ mt: 2, mb: 4, display: 'flex', justifyContent: 'flex-end' }}>
                  {/* "Terug" Button */}
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    color="secondary"
                  >
                    Terug
                  </Button>
                  {/* "Volgende" Button */}
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={
                      (activeStep === 0 && !formValues.hoofdrolspelers.trim()) ||
                      (activeStep === 1 && !formValues.verhaallocatie.trim()) ||
                      (activeStep === 2 && !formValues.verhaallijn.trim()) ||
                      (activeStep === 3 && !formValues.leesniveau) ||
                      (activeStep === 4 && !(formValues.verhaalLengte >= 250 && formValues.verhaalLengte <= 2000)) ||
                      isLoading
                    }
                    color="primary"
                    sx={{
                      borderRadius: '12px',
                      textTransform: 'none',
                      fontWeight: 600,
                    }}
                  >
                    {isLoading ? 'Bezig met maken...' : 'Volgende'}
                  </Button>
                </Box>
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>
    </>
  );
}

export default StoryForm;
