// src/App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Navigation from './components/Navigation'; // Updated Navigation Component
import HomePage from './pages/HomePage';
import OverOnsPage from './pages/OverOnsPage';
import SessionPage from './pages/SessionPage';
import NotFoundPage from './pages/NotFoundPage';
import StoryDisplay from './components/StoryDisplay'; // Import StoryDisplay
import Contact from './pages/Contact'; // Import the new Contact component
import { UUIDProvider } from './contexts/UUIDContext';
import ReactGA from 'react-ga4'; // Import ReactGA

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || 'G-YE9Z8MFMJD'; // Ensure this is set correctly

// Initialize GA outside of component to avoid re-initialization
ReactGA.initialize(TRACKING_ID);

const GAListener = () => {
  const location = useLocation();

  useEffect(() => {
    // Send pageview with the current path
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return null;
};

// New Component to handle /qrcode redirection and event logging
const QrCodeRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Log the 'qr_code_visit' event
    ReactGA.event({
      category: 'QR Code',
      action: 'qr_code_visit',
      label: 'QR Code Page Visited',
    });

    // Redirect to the home page
    navigate('/');
  }, [navigate]);

  return null; // This component doesn't render anything
};

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/over-ons" element={<OverOnsPage />} />
    <Route path="/session/:id" element={<SessionPage />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/story/:uuid" element={<StoryDisplay />} /> {/* New Route */}
    <Route path="/qrcode" element={<QrCodeRedirect />} /> {/* Added QrCodeRedirect Route */}
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

const App = () => {
  return (
    <UUIDProvider>
      <Router>
        <GAListener /> {/* GA Listener to track route changes */}
        <Navigation />
        {/* Main Content */}
        <div style={{ paddingBottom: '64px' }}> {/* 64px is the height of AppBar */}
          <AppRoutes />
        </div>
      </Router>
    </UUIDProvider>
  );
};

export default App;
