// src/contexts/UUIDContext.js

import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const UUIDContext = createContext();

// Create the provider component
export const UUIDProvider = ({ children }) => {
  const [uuidList, setUuidList] = useState([]);

  // Load UUID list from sessionStorage when the provider mounts
  useEffect(() => {
    const storedUUIDs = sessionStorage.getItem('uuidList');
    if (storedUUIDs) {
      try {
        setUuidList(JSON.parse(storedUUIDs));
      } catch (error) {
        console.error('Failed to parse uuidList from sessionStorage:', error);
      }
    }
  }, []);

  // Save UUID list to sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem('uuidList', JSON.stringify(uuidList));
  }, [uuidList]);

  // Function to add a new UUID to the list
  const addUUID = (uuid) => {
    setUuidList((prevList) => [...prevList, uuid]);
  };

  // Optional: Function to remove a UUID from the list
  const removeUUID = (uuid) => {
    setUuidList((prevList) => prevList.filter((item) => item !== uuid));
  };

  return (
    <UUIDContext.Provider value={{ uuidList, addUUID, removeUUID }}>
      {children}
    </UUIDContext.Provider>
  );
};
