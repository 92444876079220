// src/components/Navigation.js

import React, { useState, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
  Button,
  Tooltip,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail'; // Importeer het ContactMailIcon
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import DeleteIcon from '@mui/icons-material/Delete';
import { UUIDContext } from '../contexts/UUIDContext';

const drawerWidth = 240;

const Navigation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);

  const { uuidList, removeUUID } = useContext(UUIDContext);

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigateHome = () => {
    navigate('/');
  };

  const drawer = (
    <Box onClick={isMobile ? handleDrawerToggle : undefined} sx={{ textAlign: 'center' }}>
      <List>
        {/* Maak een verhaal */}
        <ListItem
          button
          component={NavLink}
          to="/"
          sx={{
            '&.active': {
              backgroundColor: theme.palette.action.selected,
            },
          }}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Maak een verhaal" />
        </ListItem>

        {/* Over Ons */}
        <ListItem
          button
          component={NavLink}
          to="/over-ons"
          sx={{
            '&.active': {
              backgroundColor: theme.palette.action.selected,
            },
          }}
        >
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="Over ons" />
        </ListItem>

        {/* Contact */}
        <ListItem
          button
          component={NavLink}
          to="/contact"
          sx={{
            '&.active': {
              backgroundColor: theme.palette.action.selected,
            },
          }}
        >
          <ListItemIcon>
            <ContactMailIcon />
          </ListItemIcon>
          <ListItemText primary="Contact" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box>
      {/* AppBar aan de onderkant */}
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: 'auto',
          bottom: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          {/* Hamburger-icoon links */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          {/* Spacer om de titel naar rechts te duwen */}
          <Box sx={{ flexGrow: 1 }} />

          {/* Titel rechts */}
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: 'inherit', cursor: 'pointer' }}
            onClick={handleNavigateHome}
          >
            Verhalen-maker.nl
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Navigation;
