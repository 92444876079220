// src/storyService.js

/**
 * Maakt een verhaal op basis van de verstrekte formulierwaarden.
 *
 * @param {Object} values - De formulierwaarden met verhaalparameters.
 * @param {string} values.hoofdrolspelers - Hoofdrolspelers van het verhaal.
 * @param {string} values.verhaallocatie - Locatie van het verhaal.
 * @param {string} values.verhaallijn - Richting van de verhaallijn.
 * @param {string} values.leesniveau - Leesniveau van het kind.
 * @param {number} values.verhaalLengte - Gewenste lengte van het verhaal in woorden.
 * @returns {Promise<Object>} - Een belofte die het UUID van het gegenereerde verhaal oplevert.
 */
export const create_story = async (values) => {
  const { hoofdrolspelers, verhaallocatie, verhaallijn, leesniveau, verhaalLengte } = values;

  try {
    // Stap 1: Maak de verhaaljob aan
    console.log('Verhaalcreatie wordt gestart...');
    console.log('Formulierwaarden:', values);

    const response = await fetch('/api/create-story', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        hoofdrolspelers,
        verhaallocatie,
        verhaallijn,
        leesniveau,
        verhaalLengte,
      }),
    });

    console.log('Verzoek verzonden naar /api/create-story');

    if (!response.ok) {
      const errorBody = await response.json();
      console.error('Kan de job niet aanmaken:', errorBody);
      throw new Error(`Kan de job niet aanmaken: ${errorBody.message || JSON.stringify(errorBody)}`);
    }

    const data = await response.json();
    console.log('Respons ontvangen van /api/create-story:', data);

    const jobId = data.jobId;

    if (!jobId) {
      console.error('Geen jobId ontvangen van de server');
      throw new Error('Geen jobId ontvangen van de server');
    }

    console.log(`Job aangemaakt met ID: ${jobId}`);

    // Stap 2: Poll de jobstatus
    const pollInterval = 2000; // 2 seconden
    const maxAttempts = 160; // Wacht tot maximaal 320 seconden
    let attempts = 0;

    return new Promise((resolve, reject) => {
      const pollJobStatus = async () => {
        try {
          console.log(`Polling jobstatus (Poging ${attempts + 1}/${maxAttempts})...`);

          const statusResponse = await fetch(`/api/story-status/${jobId}`);

          console.log('Verzoek verzonden naar /api/story-status/:jobId');

          if (!statusResponse.ok) {
            const errorBody = await statusResponse.json();
            console.error('Kan de jobstatus niet ophalen:', errorBody);
            throw new Error(`Kan de jobstatus niet ophalen: ${errorBody.message || JSON.stringify(errorBody)}`);
          }

          const statusData = await statusResponse.json();
          console.log(`Jobstatus ontvangen: ${statusData.status}`);
          console.log('Status Data:', statusData); // Debugging: volledige statusData

          if (statusData.status === 'finished') {
            if (statusData.story) {
              const { improvedStory, image_url } = statusData.story;
              if (improvedStory) {
                console.log('Verhaalgeneratie succesvol voltooid.');
                console.log('Verhaal:', improvedStory);
                if (image_url) {
                  console.log('Afbeelding URL:', image_url);
                } else {
                  console.warn('Afbeelding-URL ontbreekt in de respons. Teruggeven met lege image_url.');
                }
                const uuid = statusData.uuid; // Ensure server sends UUID
                if (uuid) {
                  resolve(uuid);
                } else {
                  console.error('UUID ontbreekt in de respons.');
                  reject(new Error('UUID ontbreekt in de respons.'));
                }
              } else {
                console.error('Verhaalinformatie ontbreekt in de respons.');
                reject(new Error('Verhaalinformatie ontbreekt in de respons.'));
              }
            } else {
              console.error('Verhaalinformatie ontbreekt in de respons.');
              reject(new Error('Verhaalinformatie ontbreekt in de respons.'));
            }
          } else if (statusData.status === 'failed') {
            console.error('Verhaalgeneratie is mislukt op de server.');
            reject(new Error('Verhaalgeneratie is mislukt op de server.'));
          } else {
            attempts += 1;
            if (attempts < maxAttempts) {
              console.log('Verhaalgeneratie nog niet voltooid. Wachten...');
              setTimeout(pollJobStatus, pollInterval);
            } else {
              console.error('Verhaalgeneratie duurt langer dan verwacht.');
              reject(new Error('Verhaalgeneratie duurt langer dan verwacht. Probeer het later opnieuw.'));
            }
          }
        } catch (error) {
          console.error('Fout bij het pollen van de jobstatus:', error);
          reject(new Error('Er is een fout opgetreden bij het controleren van de verhaalstatus. Probeer het opnieuw.'));
        }
      };

      // Start met pollen
      pollJobStatus();
    });
  } catch (error) {
    console.error('Fout bij het aanmaken van het verhaal:', error);
    throw new Error('Er is een fout opgetreden bij het starten van de verhaalgeneratie. Probeer het opnieuw.');
  }
};

/**
 * Haalt een verhaal op basis van een UUID.
 *
 * @param {string} uuid - De UUID van het verhaal.
 * @returns {Promise<Object>} - Een belofte die het verhaal en de afbeelding-URL oplevert.
 */
export const fetch_story_by_uuid = async (uuid) => {
  try {
    const response = await fetch(`/api/story/${uuid}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log(`Verzoek verzonden naar /api/story/${uuid}`);

    if (!response.ok) {
      const errorBody = await response.json();
      console.error('Kan het verhaal niet ophalen:', errorBody);
      throw new Error(`Kan het verhaal niet ophalen: ${errorBody.message || JSON.stringify(errorBody)}`);
    }

    const data = await response.json();
    console.log('Respons ontvangen van /api/story/:uuid:', data);

    const { story, image_url } = data;

    if (!story) {
      throw new Error('Verhaal ontbreekt in de respons.');
    }

    return { story, image_url: image_url || '' };
  } catch (error) {
    console.error('Fout bij het ophalen van het verhaal:', error);
    throw new Error('Er is een fout opgetreden bij het ophalen van het verhaal. Probeer het opnieuw.');
  }
};
