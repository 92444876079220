// src/pages/HomePage.js

import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Typography,
  Box,
  Button,
  Container,
  GlobalStyles,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CreateIcon from '@mui/icons-material/Create';
import adventureImage from '../adventure.png';

import StoryForm from '../components/StoryForm';
import LoadingBackdrop from '../components/LoadingBackdrop';
import CustomSnackbar from '../components/CustomSnackbar';
import useProgress from '../hooks/useProgress';

import { steps } from '../constants/steps';
import getStepContent from '../utils/getStepContent';

import ReactGA from 'react-ga4'; // Ensure ReactGA is still imported for custom events
import { useNavigate } from 'react-router-dom';

import { UUIDContext } from '../contexts/UUIDContext';

import ReCAPTCHA from 'react-google-recaptcha';

import {
  canMakeCreateStoryRequest,
  addCreateStoryTimestamp,
  getCreateStoryTimestamps,
} from '../utils/rateLimiter'; // Import rate limiter functions

const HomePage = () => {
  // State variables
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [formValues, setFormValues] = useState(() => {
    try {
      const savedFormValues = sessionStorage.getItem('formValues');
      return savedFormValues
        ? JSON.parse(savedFormValues)
        : {
            hoofdrolspelers: '',
            verhaallocatie: '',
            verhaallijn: '',
            leesniveau: '7+',
            verhaalLengte: 600,
          };
    } catch (error) {
      console.error('Failed to parse formValues from sessionStorage:', error);
      return {
        hoofdrolspelers: '',
        verhaallocatie: '',
        verhaallijn: '',
        leesniveau: '7+',
        verhaalLengte: 600,
      };
    }
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uuid, setUuid] = useState(null);

  const [openLoadingSnackbar, setOpenLoadingSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openShareSnackbar, setOpenShareSnackbar] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [fadeIn, setFadeIn] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const { progress, startProgress, clearProgress } = useProgress();

  const shareTimerRef = useRef(null);

  const bottomRef = useRef(null);
  const topRef = useRef(null);

  const navigate = useNavigate();

  const { addUUID } = useContext(UUIDContext);

  const recaptchaRef = useRef(null);

  const [remainingRequests, setRemainingRequests] = useState(5);

  // **Removed GA Initialization useEffect**

  // Save formValues to sessionStorage on every change
  useEffect(() => {
    sessionStorage.setItem('formValues', JSON.stringify(formValues));
  }, [formValues]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      clearProgress();
      if (shareTimerRef.current) {
        clearTimeout(shareTimerRef.current);
      }
    };
  }, [clearProgress]);

  // Automatically mark steps as completed based on formValues
  useEffect(() => {
    const newCompleted = {};
    if (formValues.hoofdrolspelers.trim() !== '') {
      newCompleted[0] = true;
    }
    if (formValues.verhaallocatie.trim() !== '') {
      newCompleted[1] = true;
    }
    if (formValues.verhaallijn.trim() !== '') {
      newCompleted[2] = true;
    }
    if (formValues.leesniveau) {
      newCompleted[3] = true;
    }
    if (formValues.verhaalLengte >= 250 && formValues.verhaalLengte <= 2000) {
      newCompleted[4] = true;
    }
    setCompleted(newCompleted);
  }, [formValues]);

  // Determine if the form is fully filled out
  const isFormValid =
    formValues.hoofdrolspelers.trim() !== '' &&
    formValues.verhaallocatie.trim() !== '' &&
    formValues.verhaallijn.trim() !== '';

  // Handle Next button (only for step navigation)
  const handleNext = () => {
    const newStep = activeStep + 1;
    setActiveStep(newStep);
    // **Removed GA pageview send**
  };

  // Handle Back button
  const handleBack = () => {
    const newStep = activeStep - 1;
    setActiveStep(newStep);
    // **Removed GA pageview send**
  };

  // Handle form changes
  const handleChange = (field) => (event, newValue) => {
    setFormValues({
      ...formValues,
      [field]: newValue !== undefined ? newValue : event.target.value,
    });
  };

  /**
   * Handle Create Story with CAPTCHA Token
   * @param {string} captchaToken - The CAPTCHA verification token from frontend
   */
  const handleCreateStory = async (captchaToken) => {
    // Controleer rate limit
    if (!canMakeCreateStoryRequest()) {
      setErrorMessage(
        'Je hebt het maximale aantal verhalen gemaakt, probeer het later opnieuw. Indien je een school bent, stuur een email naar info@verhalen-maker.nl om je school te "whitelisten".'
      );
      setOpenErrorSnackbar(true);
      return;
    }

    const { hoofdrolspelers, verhaallocatie, verhaallijn } = formValues;
    if (
      hoofdrolspelers.trim() === '' ||
      verhaallocatie.trim() === '' ||
      verhaallijn.trim() === ''
    ) {
      setErrorMessage('Vul alstublieft alle verplichte velden in.');
      setOpenErrorSnackbar(true);
      return;
    }

    // Start loading
    setIsLoading(true);
    setOpenLoadingSnackbar(true);
    startProgress();

    try {
      // Stuur het verzoek naar de backend
      const response = await fetch('/api/create-story', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formValues, captchaToken }),
      });
      
      ReactGA.event({
        category: 'Story',
        action: 'story_initiated',
        label: 'User completed story generation',
      });

      const data = await response.json();

      if (response.ok) {
        // Voeg het huidige tijdstip toe aan de rate limiter
        addCreateStoryTimestamp();

        // **Retained existing custom event for form submission if applicable**
        // (Assuming no event was added here in the previous step)

        // Poll de job status totdat deze voltooid is
        const jobId = data.jobId; // Ontvang jobId van de backend

        const pollJobStatus = async () => {
          try {
            const statusResponse = await fetch(`/api/story-status/${jobId}`);
            const statusData = await statusResponse.json();

            if (statusData.status === 'completed') {
              const retrievedUuid = statusData.uuid;
              setUuid(retrievedUuid);
              addUUID(retrievedUuid);

              setFadeIn(true);
              setShowContent(false);
              setIsLoading(false);
              setOpenLoadingSnackbar(false);
              setOpenSuccessSnackbar(true);
              clearProgress();

              // Log de verhaalcreatie gebeurtenis
              ReactGA.event({
                category: 'Story',
                action: 'story_completed',
                label: 'User completed story generation',
              });

              // **Removed manual pageview send**

              // Redirect naar StoryDisplay met UUID
              navigate(`/story/${retrievedUuid}`);

              shareTimerRef.current = setTimeout(() => {
                setOpenShareSnackbar(true);
              }, 120000);
            } else if (statusData.status === 'failed') {
              throw new Error(statusData.reason || 'create_story_job_failed');
            } else {
              // Nog steeds in uitvoering; poll opnieuw na een vertraging
              setTimeout(pollJobStatus, 3000); // Poll elke 3 seconden
            }
          } catch (error) {
            console.error('Error polling job status:', error);
            setErrorMessage(error.message || 'Er is een onverwachte fout opgetreden.');
            setIsLoading(false);
            setOpenLoadingSnackbar(false);
            setOpenErrorSnackbar(true);
            clearProgress();

            // Log de fout gebeurtenis
            ReactGA.event({
              category: 'Error',
              action: 'create_story_failed',
              label: error.message || 'Unknown error',
            });
          }
        };

        // Start polling
        pollJobStatus();
      } else {
        throw new Error(data.error || 'Er is een onverwachte fout opgetreden.');
      }
    } catch (error) {
      console.error('Error in handleCreateStory:', error);
      setErrorMessage(error.message || 'Er is een onverwachte fout opgetreden.');
      setIsLoading(false);
      setOpenLoadingSnackbar(false);
      setOpenErrorSnackbar(true);
      clearProgress();

      // Log de fout gebeurtenis
      ReactGA.event({
        category: 'Error',
        action: 'create_story_failed',
        label: error.message || 'Unknown error',
      });
    }
  };

  // Close snackbars
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenLoadingSnackbar(false);
    setOpenErrorSnackbar(false);
    setOpenSuccessSnackbar(false);
    setOpenShareSnackbar(false);

    if (openErrorSnackbar) {
      setErrorMessage('');
    }
  };

  // Scroll to bottom when form is valid
  useEffect(() => {
    if (isFormValid && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isFormValid]);

  // Scroll to top when story is generated
  useEffect(() => {
    if (uuid && topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [uuid]);

  // Update remainingRequests bij initialisatie en elke minuut
  useEffect(() => {
    const updateRemainingRequests = () => {
      const oneHourAgo = Date.now() - 60 * 60 * 1000;
      const timestamps = getCreateStoryTimestamps().filter(
        (timestamp) => timestamp > oneHourAgo
      );
      setRemainingRequests(5 - timestamps.length);
    };

    updateRemainingRequests();

    const interval = setInterval(() => {
      updateRemainingRequests();
    }, 60000); // Update elke minuut

    return () => clearInterval(interval);
  }, []);

  // Handle form submission via reCAPTCHA
  const handleSubmit = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.execute(); // Execute Invisible reCAPTCHA
    }
  };

  // Handle reCAPTCHA verification
  const onReCAPTCHAChange = (token) => {
    if (token) {
      handleCreateStory(token);
      recaptchaRef.current.reset(); // Reset voor toekomstig gebruik
    }
  };

  return (
    <>
      {/* Global Styles for Bounce Animation */}
      <GlobalStyles
        styles={{
          '@keyframes bounce': {
            '0%, 20%, 50%, 80%, 100%': {
              transform: 'translateY(0)',
            },
            '40%': {
              transform: 'translateY(-10px)',
            },
            '60%': {
              transform: 'translateY(-5px)',
            },
          },
        }}
      />

      {/* Main Content */}
      <Container
        maxWidth="md"
        sx={{
          mt: 6,
          mb: 6,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Attach the topRef to a wrapping div */}
        <div ref={topRef}>
          {showContent && (
            <div>
              {/* Hero Image */}
              <Box
                sx={{
                  width: '100%',
                  height: { xs: '200px', sm: '300px', md: '400px' },
                  backgroundImage: `url(${adventureImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: 2,
                  mb: 4,
                }}
              />
              
              {/* Main Heading */}
              <Typography
                variant="h3"
                align="center"
                gutterBottom
                sx={{ mt: 4, mb: 4 }}
              >
                Creëer je eigen unieke kinderavontuur!
              </Typography>
              
              {/* Subheading */}
              <Typography
                variant="subtitle1"
                align="center"
                gutterBottom
                sx={{ mt: 2, mb: 6 }}
              >
                Welkom bij onze verhalenmaker! In een paar eenvoudige stappen kun je
                een persoonlijk verhaal samenstellen voor jouw kind(eren) om van te
                genieten voor het slapengaan. Volg de onderstaande stappen en laat
                de magie beginnen.
              </Typography>

              {/* StoryForm Component */}
              <StoryForm
                activeStep={activeStep}
                steps={steps}
                completed={completed}
                getStepContent={(step) => getStepContent(step, formValues, handleChange)}
                handleNext={handleNext}
                handleBack={handleBack}
                isLoading={isLoading}
                formValues={formValues}
                setActiveStep={setActiveStep}
                handleChange={handleChange}
              />

              {/* Invisible reCAPTCHA Component */}
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                size="invisible"
                ref={recaptchaRef}
                onChange={onReCAPTCHAChange}
              />

              {/* "Create Story" Button at the Bottom */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 6,
                  mt: 4,
                  width: '100%',
                }}
              >
                <Button
                  variant="contained"
                  color={isFormValid ? 'primary' : 'primary'}
                  startIcon={isFormValid ? <DownloadIcon /> : <CreateIcon />}
                  onClick={handleSubmit}
                  disabled={
                    !isFormValid || isLoading // Removed remainingRequests condition
                  }
                  sx={{
                    borderRadius: '10px',
                    textTransform: 'none',
                    fontWeight: 600,
                    paddingY: 1.5,
                    paddingX: 3,
                    animation: isFormValid ? 'bounce 1s infinite' : 'none',
                  }}
                >
                  {isFormValid ? 'Klik hier om je verhaal te maken!' : 'Vul de velden in!'}
                </Button>
              </Box>
            </div>
          )}
        </div>

        {/* Snackbars */}
        <CustomSnackbar
          open={openLoadingSnackbar}
          handleClose={handleCloseSnackbar}
          severity="info"
          message="Het verhaal wordt gemaakt, dit kan een paar minuten duren, dus ga gerust je tanden poetsen ;-)"
          autoHideDuration={null}
        />

        <CustomSnackbar
          open={openErrorSnackbar}
          handleClose={handleCloseSnackbar}
          severity="error"
          message={errorMessage}
        />

        <CustomSnackbar
          open={openSuccessSnackbar}
          handleClose={handleCloseSnackbar}
          severity="success"
          message="Verhaal is gemaakt! Veel leesplezier."
        />

        {/* Loading Backdrop */}
        <LoadingBackdrop isLoading={isLoading} progress={progress} />

        {/* Dummy div for scrolling to bottom */}
        <div ref={bottomRef} />
      </Container>
    </>
  );
};

export default HomePage;
