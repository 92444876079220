// src/utils/getStepContent.js

import React from 'react';
import {
  Typography,
  TextField,
  Box,
  Slider,
} from '@mui/material';
import { leesniveaus } from '../constants/leesniveaus';

function getStepContent(step, formValues, handleChange) {
  switch (step) {
    case 0:
      return (
        <div>
          <Typography variant="h6">
            Geef aan wie de hoofdrolspelers van het verhaal zijn
          </Typography>
          <TextField
            label="Namen, leeftijden en beschrijvingen van de kinderen"
            placeholder="Bijv. Jan (6 jaar, houdt van piraten), Lisa (4 jaar, houdt van prinsessen)"
            multiline
            fullWidth
            margin="normal"
            value={formValues.hoofdrolspelers}
            onChange={handleChange('hoofdrolspelers')}
          />
        </div>
      );
    case 1:
      return (
        <div>
          <Typography variant="h6">
            Geef aan waar het verhaal zich af gaat spelen
          </Typography>
          <TextField
            label="Waar speelt het verhaal zich af?"
            placeholder="Bijv. Een magisch bos, de ruimte, een middeleeuws kasteel"
            fullWidth
            margin="normal"
            value={formValues.verhaallocatie}
            onChange={handleChange('verhaallocatie')}
          />
        </div>
      );
    case 2:
      return (
        <div>
          <Typography variant="h6">
            Geef een richting aan het verhaal, waar wil je dat het ongeveer over
            gaat?
          </Typography>
          <TextField
            label="Waar gaat het verhaal over?"
            placeholder="Bijv. Een avontuur om een verloren schat te vinden"
            multiline
            fullWidth
            margin="normal"
            value={formValues.verhaallijn}
            onChange={handleChange('verhaallijn')}
          />
        </div>
      );
    case 3:
      return (
        <div>
          <Typography variant="h6">Wat is het leesniveau?</Typography>
          <Box sx={{ width: '100%', mt: 4, px: 2 }}>
            {/* Toon het geselecteerde leesniveau */}
            <Typography variant="subtitle1" align="center" gutterBottom>
              {formValues.leesniveau}
            </Typography>
            {/* Slider voor leesniveau */}
            <Slider
              value={getLeesniveauIndex(formValues.leesniveau)}
              onChange={handleLeesniveauChange}
              aria-labelledby="leesniveau-slider"
              step={1}
              marks={false}
              min={0}
              max={leesniveaus.length - 1}
              valueLabelDisplay="auto"
              sx={{
                color: 'primary.main',
              }}
            />
          </Box>
        </div>
      );
    case 4:
      return (
        <div>
          <Typography variant="h6">Hoe lang moet het verhaal zijn?</Typography>
          <Box sx={{ width: '100%', mt: 4 }}>
            <Slider
              value={formValues.verhaalLengte}
              onChange={handleChange('verhaalLengte')}
              aria-labelledby="verhaalLengte-slider"
              valueLabelDisplay="auto"
              step={100}
              marks={[
                { value: 200, label: '200' },
                { value: 500, label: '500' },
                { value: 800, label: '800' },
                { value: 1000, label: '1000' },
              ]}
              min={200}
              max={1000}
            />
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              Lengte: {formValues.verhaalLengte} woorden
            </Typography>
          </Box>
        </div>
      );
    default:
      return 'Onbekende stap';
  }

  // Helperfunctie om de index van het leesniveau te krijgen
  function getLeesniveauIndex(leesniveau) {
    const index = leesniveaus.findIndex((niveau) => niveau.label === leesniveau);
    return index !== -1 ? index : 0; // Default naar eerste niveau indien niet gevonden
  }

  // Handler voor leesniveau wijzigingen
  function handleLeesniveauChange(event, newValue) {
    const selectedLeesniveau = leesniveaus[newValue].label;
    handleChange('leesniveau')({
      target: { value: selectedLeesniveau },
    });
  }
}

export default getStepContent;
