import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <Box textAlign="center" mt={10}>
      <Typography variant="h4" gutterBottom>
        404 - Pagina Niet Gevonden
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Oeps! De pagina die je zoekt bestaat niet.
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
        Ga naar Home
      </Button>
    </Box>
  );
};

export default NotFoundPage;
