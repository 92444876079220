// src/utils/rateLimiter.js

/**
 * Haalt de timestamps van create_story verzoeken op uit sessionStorage.
 * @returns {number[]} Array van timestamps in milliseconden.
 */
export const getCreateStoryTimestamps = () => {
    const data = sessionStorage.getItem('createStoryTimestamps');
    return data ? JSON.parse(data) : [];
  };
  
  /**
   * Voegt het huidige timestamp toe aan de create_story verzoeken.
   */
  export const addCreateStoryTimestamp = () => {
    const timestamps = getCreateStoryTimestamps();
    const now = Date.now();
    timestamps.push(now);
    sessionStorage.setItem('createStoryTimestamps', JSON.stringify(timestamps));
  };
  
  /**
   * Controleert of de gebruiker nog een create_story verzoek kan doen.
   * @returns {boolean} True als verzoek mogelijk is, anders false.
   */
  export const canMakeCreateStoryRequest = () => {
    const oneHourAgo = Date.now() - 60 * 60 * 1000;
    let timestamps = getCreateStoryTimestamps();
  
    // Filter uit oude timestamps
    timestamps = timestamps.filter((timestamp) => timestamp > oneHourAgo);
    sessionStorage.setItem('createStoryTimestamps', JSON.stringify(timestamps));
  
    return timestamps.length < 5;
  };
  